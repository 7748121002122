import React, { PureComponent } from 'react'
import { lang } from '../../themeConfig'
import { getDdateOfRegistration, getMileage, getRegistration, MOT, V5, VehicleStatsRow } from './VehicleHelper'
import { CapCleanPrice, NamaGrade, Warranty } from './VehicleDetailsHelper'

export default class VehicleDetailsPanel extends PureComponent {
    render() {
        const { vehicle, user } = this.props

        let notes = []
        if (vehicle.nonRunner) notes.push('NON RUNNER')
        if (vehicle.imported) notes.push('IMPORTED')
        if (vehicle.remarks) notes.push(vehicle.remarks)

        return (
            <div className="row m-0 p-0">
                <div className="col-12 col-md-6 m-0 p-0 pr-md-2">
                    <h3 className="m-0 pt-3 pr-1 pb-0 pl-1">{lang('VEHICLE_DETAILS_TITLE', user.theme)}</h3>
                    <table className="table m-0 p-0">
                        <tbody>
                            <tr>
                                <td>{lang('LABEL_DOR', user.theme)}</td>
                                <td>{getDdateOfRegistration(vehicle)}</td>
                            </tr>
                            <tr>
                                <td>{lang('LABEL_REG', user.theme)}</td>
                                <td>{getRegistration(vehicle)}</td>
                            </tr>
                            <tr>
                                <td>{lang('LABEL_MILEAGE', user.theme)}</td>
                                <td>{getMileage(vehicle)}</td>
                            </tr>
                            <Warranty vehicle={vehicle} user={user} />
                            <VehicleStatsRow label={lang('LABEL_COLOUR', user.theme)} value={vehicle.colour} />
                            <VehicleStatsRow label={lang('LABEL_BODY_TYPE', user.theme)} value={vehicle.bodyType} />
                            <VehicleStatsRow label={lang('LABEL_DOORS', user.theme)} value={vehicle.doors} />
                            <VehicleStatsRow label={lang('LABEL_FUEL', user.theme)} value={vehicle.fuel} />
                            <VehicleStatsRow
                                label={lang('LABEL_TRANSMISSION', user.theme)}
                                value={vehicle.transmission}
                            />
                            <V5 vehicle={vehicle} user={user} />
                            <MOT vehicle={vehicle} />
                            <VehicleStatsRow label={lang('LABEL_CO2', user.theme)} value={vehicle.co2} />
                        </tbody>
                    </table>
                </div>
                <div className="col-12 col-md-6 m-0 p-0 pl-md-2">
                    <h3 className="m-0 pt-3 pr-1 pb-0 pl-1">&nbsp;</h3>
                    <table className="table m-0 p-0">
                        <tbody>
                            <VehicleStatsRow
                                label={lang('LABEL_VAT_STATUS', user.theme)}
                                value={vehicle.vatStatus ? vehicle.vatStatus.label : null}
                            />
                            <tr>
                                <td>{lang('LABEL_FORMER_KEEPERS', user.theme)}</td>
                                <td>{vehicle.formerKeepers ? vehicle.formerKeepers : '-'}</td>
                            </tr>
                            <VehicleStatsRow label={lang('LABEL_SELLER', user.theme)} value={vehicle.seller} />
                            <VehicleStatsRow label={lang('LABEL_SERVICE', user.theme)} value={vehicle.serviceHistory} />
                            <VehicleStatsRow label={lang('LABEL_NOTES', user.theme)} value={notes.join(' - ')} />
                            <VehicleStatsRow
                                label={lang('LABEL_MECHANICAL_FAULTS', user.theme)}
                                value={vehicle.inspection && vehicle.inspection.mechanicalFaults}
                            />
                            <NamaGrade vehicle={vehicle} user={user} />
                            <CapCleanPrice vehicle={vehicle} user={user} />
                            <VehicleStatsRow label="Euro Status" value={vehicle.eurostatus} />
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
