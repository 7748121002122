import React, { Component } from 'react'
import { Tooltip } from 'reactstrap'

import { alertVehicle, insuranceWriteOffCategory } from '../../../utilities/Functions'
import { lang } from '../../../themeConfig'

/**
 * Usage:
 *   <VehicleHeader />
 */
class VehicleIcons extends Component {
    constructor(props) {
        super(props)

        this.toggle = this.toggle.bind(this)
        this.state = {
            nonRunner: false,
            warning: false,
            assured: false,
            insuranceWriteoff: false,
            imported: false,
        }
    }

    toggle(key) {
        this.setState({
            [key]: !this.state[key],
        })
    }

    assuredImage = (vehicle) => {
        let label = vehicle.assuredType
        if (label && label.includes('AA')) {
            if (label.includes('Lite')) return '/img/icon-aa-assured-lite.png'
            else if (label.includes('EV')) return '/img/icon-aa-assured-ev.png'
            else return '/img/icon-aa-assured-std.png'
        }

        return '/img/icon-assured.svg'
    }

    assuredTooltip = (vehicle, theme) => {
        let label = vehicle.assuredType
        if (label && label.includes('AA')) {
            if (label.includes('Lite')) return lang('AA_ASSURED_LITE', theme)
            else if (label.includes('EV')) return lang('AA_ASSURED_HYBRID', theme)
            else return lang('AA_ASSURED_STANDARD', theme)
        }

        return lang('ASTON_BARCLAY_ASSURED', theme)
    }

    render = () => {
        const { vehicle, withText, vehicleCard, detailsScreen, theme } = this.props
        const nonRunnerId = `non-runner${vehicle.id}`
        const warningId = `warning${vehicle.id}`
        const assuredId = `assured${vehicle.id}`
        const insuranceId = `insurance${vehicle.id}`
        const assuredIcon = this.assuredImage(vehicle)
        const assuredLabel = this.assuredTooltip(vehicle, theme)
        const importedId = `imported${vehicle.id}`
        return (
            <span className="vehicle__icons">
                {vehicle.writeoffcode && (
                    <React.Fragment>
                        <div>
                            <img
                                height="26rem"
                                width="40rem"
                                src={`/img/icon-write-off-cat-${insuranceWriteOffCategory(vehicle)}.png`}
                                alt={vehicle.writeoffcode}
                                id={insuranceId}
                            />
                            <Tooltip
                                placement="bottom"
                                target={insuranceId}
                                isOpen={this.state.insuranceWriteoff}
                                toggle={() => this.toggle('insuranceWriteoff')}
                            >
                                {vehicle.writeoffcode}
                            </Tooltip>
                        </div>
                    </React.Fragment>
                )}
                {vehicle.assuredUrl && (
                    <React.Fragment>
                        <div>
                            {vehicleCard ? (
                                <img height="26rem" width="26rem" src={assuredIcon} alt={assuredLabel} id={assuredId} />
                            ) : (
                                <a href={vehicle.assuredUrl} target="_blank" rel="noopener noreferrer">
                                    <img height="26rem" width="26rem" src={assuredIcon} alt="" id={assuredId} />
                                </a>
                            )}
                            {withText && <span>{assuredLabel}</span>}
                            <Tooltip
                                placement="bottom"
                                target={assuredId}
                                isOpen={this.state.assured}
                                toggle={() => this.toggle('assured')}
                            >
                                {assuredLabel}
                            </Tooltip>
                        </div>
                    </React.Fragment>
                )}
                {alertVehicle(vehicle) && (
                    <React.Fragment>
                        <div>
                            <img
                                height="26rem"
                                width="26rem"
                                src="/img/icon-police-stolen.svg"
                                alt="Warning"
                                id={warningId}
                            />
                            {withText && <span>{lang('TOOLTIP_ALERT_VEHICLE_DESCRIPTION', theme)}</span>}
                            <Tooltip
                                placement="bottom"
                                target={warningId}
                                isOpen={this.state.warning}
                                toggle={() => this.toggle('warning')}
                            >
                                {lang('TOOLTIP_ALERT_VEHICLE_DESCRIPTION', theme)}
                            </Tooltip>
                        </div>
                    </React.Fragment>
                )}
                {vehicle.nonRunner && (
                    <React.Fragment>
                        <div>
                            <img
                                height="26rem"
                                width="26rem"
                                src="/img/icon-non-runner.svg"
                                alt="Non Runner"
                                id={nonRunnerId}
                            />
                            {withText && <span>Non runner</span>}
                            <Tooltip
                                placement="bottom"
                                target={nonRunnerId}
                                isOpen={this.state.nonRunner}
                                toggle={() => this.toggle('nonRunner')}
                            >
                                {lang('LABEL_NON_RUNNER', theme)}
                            </Tooltip>
                        </div>
                    </React.Fragment>
                )}
                {vehicle.imported && (
                    <React.Fragment>
                        <div>
                            <img
                                height="26rm"
                                width="26rem"
                                src={`/img/icon-import-${detailsScreen ? 'black' : 'white'}.svg`}
                                alt="Imported"
                                id={importedId}
                            />
                            {withText && <span>Imported</span>}
                            <Tooltip
                                placement="bottom"
                                target={importedId}
                                isOpen={this.state.imported}
                                toggle={() => this.toggle('imported')}
                            >
                                {lang('LABEL_IMPORTED', theme)}
                            </Tooltip>
                        </div>
                    </React.Fragment>
                )}
            </span>
        )
    }
}

export default VehicleIcons
